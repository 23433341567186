html.container-module .main-app.main-app-dark {
--color-primary-dark:#bfdbfe;--color-primary-action:#60a5fa;--color-primary-regular:#1e3a8a;--color-disabled:var(--color-gray300);--color-primary-light:#172554;--color-black:#f3f4f6;--color-gray1:#eaecf0;--color-dark-gray2:#dcdfe4;--color-gray2:#bac0ca;--color-gray3:#878f9c;--color-gray4:#6f7785;--color-gray5:#4b5463;--color-gray6:#373f4d;--color-gray7:#20293a;--color-white:#111827;--color-gray800:#f3f4f6;--color-gray700:#eaecf0;--color-gray600:#dcdfe4;--color-gray500:#bac0ca;--color-gray400:#878f9c;--color-gray300:#6f7785;--color-gray200:#4b5463;--color-gray100:#373f4d;--color-gray50:#20293a;--color-hover:#93c5fd;--color-focus:#3b82f6;--color-blue700:#93c5fd;--color-blue500:#3b82f6;--color-warning:#ea580c;--color-alert-background:#7f1515;--color-warning-light:#713f12;--color-success-background:#14532d;--color-alert-background-light:#450a0a;--color-informational-background-light:#172554;--color-success-background-light:#062e16;--field-border:var(--color-gray300);--field-disabled-fill:var(--color-gray100);--shadow-md:0 2px 4px -1px hsla(0,0%,100%,.1),0 4px 6px -1px hsla(0,0%,100%,.1);--shadow-xlg:0 20px 25px -5px hsla(0,0%,100%,.1),0 10px 10px -5px hsla(0,0%,100%,.04)
}

html.container-module .main-app.main-app-dark .menu-subitems-wrapper {
--color-white:#111827
}

html.container-module .main-app.main-app-dark .menu-subitems-wrapper .menu-subitem-v2:hover,html.container-module .main-app.main-app-dark .menu-subitems-wrapper .menu-subitem-v2.subitem-selected {
color:var(--color-black)
}

html.container-module .main-app.main-app-dark .container-menu-item-header,html.container-module .main-app.main-app-dark .selectable-list-dropdown-wrapper {
--color-black:#f3f4f6
}

html.container-module .main-app.main-app-dark .ac-menu-bar {
--color-white:#f3f4f6;--color-black:#111827
}

html.container-module .main-app.main-app-dark .ac-menu-v2-button {
--menu-button-background:var(--menu-button-background-dark)
}

html.container-module .main-app.main-app-dark .menu-bar,html.container-module .main-app.main-app-dark .ac-badge-light-text {
--color-white:#f3f4f6
}

html.container-module .main-app.main-app-dark .menu-bar .ac-tooltip,html.container-module .main-app.main-app-dark .ac-badge-light-text .ac-tooltip {
--color-black:#f3f4f6;--color-white:#111827
}

html.container-module .main-app.main-app-dark .ac-framed-badge {
--color-black:#111827;--color-white:#f3f4f6
}

html.container-module .main-app.main-app-dark .ac-badge:not(.ac-badge-informational).ac-badge-dark-text .ac-badge-content-background {
--color-black:#111827;color:var(--color-black)
}

html.container-module .main-app.main-app-dark .ac-badge:not(.ac-badge-informational).ac-badge-light-text .ac-badge-content-background {
--color-light:#f3f4f6;color:var(--color-light)
}

html.container-module .main-app.main-app-dark .ac-badge.ac-badge-disabled {
--color-disabled:#bac0ca
}

html.container-module .main-app.main-app-dark .ac-toast-warning {
--color-black:#111827
}

html.container-module .main-app.main-app-dark .selectable-list-dropdown-wrapper.ac-selectable-list-container {
--color-white:#111827
}

html.container-module .main-app.main-app-dark .selectable-list-dropdown-wrapper.ac-selectable-list-container .option-field-container:hover {
--color-primary-light:#93c5fd;--color-black:#111827
}

