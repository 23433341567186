html.container-module {
--menu-bar-color: var(--color-black);--color-pink-in-training: #c30f98
}

.container-module #container-module {
display:flex
}

.container-module #container-module .container-main-menu {
flex-direction:row
}

.container-module #container-module .container-main-menu .menu-item-hidden {
visibility:visible
}

.container-module #application-container {
display:flex;flex-direction:column;width:100%;height:100dvh;min-height:100dvh;padding:0;margin:0;overflow:hidden
}

.container-module #application-container>#root {
display:flex;flex-grow:1;overflow:hidden
}

.container-module #application-container>.ember-view:not(.ac-position-wrapper-addon-base) {
display:flex;flex-grow:1;flex-direction:column;height:100%
}

.container-module #application-container .ac-main-menu {
background-color:var(--menu-bar-color)
}

.container-module #application-container .ac-main-menu .ac-select-wrapper.ac-select-dark:not(:hover)>.ac-dropdown-target-default>.ac-multiselect__control {
background-color:var(--menu-bar-color);box-shadow:var(--menu-bar-color)
}

.container-module #application-container .ac-main-menu .property-select .ember-power-select-trigger {
background-color:var(--menu-bar-color)
}

.container-module .pinned-context-help #application-container {
width:calc(100% - var(--sp-context-help-width) - var(--menu-width))
}

.container-module .pinned-context-help #application-container .ac-main-menu {
position:fixed;right:0
}

.container-module .pinned-context-help #application-container .ac-main-menu .property-container {
margin-top:0
}

.container-module .pinned-context-help #application-container .ac-side-panel-page.ac-side-panel-right {
right:var(--sp-context-help-width)
}

.container-module .pinned-context-help #application-container .ac-side-panel.ac-side-panel-right.sc-ac-side-panel-h {
right:var(--sp-context-help-width)
}

.container-module .pinned-context-help #application-container .ac-side-panel.ac-side-panel-right.ac-side-panel-inline.sc-ac-side-panel-h {
right:0
}

.container-module .pinned-context-help #application-container .ac-side-panel ac-footer .ac-footer-fixed {
left:0;width:100%
}

.container-module #container-module:not(:empty) {
height:var(--menu-height)
}

.container-module #container-module:not(:empty)+#application-container {
height:calc(100dvh - var(--menu-height));min-height:calc(100dvh - var(--menu-height))
}

.container-module #container-module+#application-container #ac-main-menu,.container-module #container-module+#application-container .ac-main-menu {
z-index:calc(var(--z-index-main-menu) - 1)
}

